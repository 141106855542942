import {addLetterModal} from './../form/tacho_shared';
function CreateLetterButtonEvents()
{
    app.DOM.table.find('.btn-create').on('click', function(){

        const letterType = $(this).attr('data-letter-type');
        const range = ( letterType === 'day' ) ? $(this).attr('data-letter-range') : $(this).attr('data-letter-range').split('/');

        addLetterModal(
            'list_tacho_infringements',
            $(this),
            $(this).attr('data-tacho-driver-id'),
            letterType,
            range,
            $(this).attr('data-driver-name')
        );
    });
}

$( () => {
    CreateLetterButtonEvents();
});